import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = props => (
    <Layout {...props}>
        <SEO
            title="פילאטיס בראשון לציון | עיצוב בראשון לציון | זומבה בראשון לציון"
            keywords={[`פילאטיס`, `זומבה`, `עיצוב וחיטוב`, `סטודיו לנשים`]}
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '3rem auto' }}>
            <h1 style={{ textAlign: 'center' }}>הדף המבוקש לא נמצא</h1>
            <p>אבל את עדיין יכולה ליצור איתנו קשר :)</p>
        </div>
    </Layout>
);

export default NotFoundPage;
